<template>
  <v-row class="ma-0">
    <v-col
      cols="12"
      lg="6"
      xl="6"
      class="primary d-none d-md-flex align-center justify-center"
    >
      <v-container>
        <div class="pa-10">
          <v-row justify="center">
            <img src="/img/gerb3.png" style="opacity: 0.5" />
          </v-row>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="6" xl="6" class="d-flex align-center">
      <v-container>
        <div class="px-7 pt-0">
          <center>
          <h3 class="grey--text">
            Инновационная платформа обучения <br />
            личного состава подразделений пожарной охраны
          </h3>
          <br />
          <!---<h1
            class="font-weight-thin blue--text text--lighten-2"
            style="
              font-weight: 900 !important;
              font-size: 40px;
              display: inline;
              margin-top: 1px;
            "
          >
            Подготовка
          </h1>-->
          <h2 class="font-weight-bold mt-0 blue-grey--text text--darken-2" >
                Регистрация
              </h2>
              <h6 class="subtitle-1" >
                Уже зарегистрированы?
                <router-link to="/authentication/login">Войти</router-link>
              </h6>
        </center>
          <!---<img src="/gerb.png" id="logo" style="height: 62px; margin-top:10px;"/><h1 class="font-weight-thin blue--text text--lighten-2" style="font-weight: 900 !important;font-size: 60px; display: inline; margin-top: 5px;">ЭИОС МЧС РОССИИ</h1>-->

          <v-row>
            <v-col cols="12" lg="9" xl="6">


              <v-form
                ref="form"
                lazy-validation
                v-on:submit.prevent="register()"
              >
                <v-text-field
                  v-model="data.body.name"
                  :rules="fnameRules"
                  :error="errors.name"
                  :error-messages="errors.name"
                  label="Главное управление МЧС России по субъекту РФ"
                  placeholder="Главное управление МЧС России по субъекту РФ"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="data.body.last_name"
                  :rules="fnameRules"
                  :error="errors.last_name"
                  :error-messages="errors.last_name"
                  label="Структурное подразделение (Номер ПСЧ/СПСЧ)"
                  placeholder="Структурное подразделение (Номер ПСЧ/СПСЧ)"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="data.body.second_name"
                  :error="errors.second_name"
                  :error-messages="errors.second_name"
                  label="Занимаемая должность"
                  placeholder="Занимаемая должность"
                ></v-text-field>
                <v-text-field
                  v-model="data.body.organization"
                  :rules="fnameRules"
                  :error="errors.organization"
                  :error-messages="errors.organization"
                  label="Номер караула"
                  placeholder="Номер караула"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="data.body.position"
                  :rules="fnameRules"
                  :error="errors.position"
                  :error-messages="errors.position"
                  label="Номер по книге службы"
                  placeholder="Номер по книге службы"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="data.body.email"
                  :rules="emailRules"
                  label="E-mail"
                  placeholder="E-mail"
                  :error-messages="errors.email"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="data.body.password"
                  :rules="passwordRules"
                  label="Пароль"
                  placeholder="Пароль"
                  required
                  :error-messages="errors.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                ></v-text-field>
                <v-text-field
                  v-model="data.body.password2"
                  :rules="passwordRules"
                  label="Подтвердите пароль"
                  placeholder="Подтвердите пароль"
                  :error-messages="errors.password"
                  required
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                ></v-text-field>
                <v-btn
                  :disabled="logging"
                  color="info"
                  block
                  class="mr-4"
                  submit
                  @click="register()"
                  >Зарегистрироваться</v-btn
                >
              </v-form>
              <div class="text-center mt-6" v-if="1 !== 1">
                <v-chip pill class="mr-2">
                  <v-avatar left>
                    <v-btn color="blue lighten-1" class="white--text">
                      <v-icon>mdi-twitter</v-icon>
                    </v-btn> </v-avatar
                  >Twitter
                </v-chip>
                <v-chip pill>
                  <v-avatar left>
                    <v-btn color="teal lighten-2" class="white--text">
                      <v-icon>mdi-github</v-icon>
                    </v-btn> </v-avatar
                  >Github
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>

    <v-dialog v-model="successRegister" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Аккаунт создан </v-card-title>
        <v-card-text
          >Ваш аккаунт успешно создан. Инструкция по активации отправлена на
          указанный e-mail адрес.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="registerDone()">
            Понятно
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "FullLogin",
  data: () => ({
    successRegister: false,
    password: "",
    show1: false,
    passwordRules: [
      (v) => !!v || "Нужно ввести пароль",
      (v) => (v && v.length >= 6) || "Пароль должен содержать более 6 символов",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail должен быть заполнен",
      (v) => /.+@.+\..+/.test(v) || "E-mail введен неверно",
    ],
    checkbox: false,
    fname: "",
    fnameRules: [(v) => !!v || "Это поле должно быть заполнено"],
    data: {
      body: {
        username: "",
        password: "",
        password2: "",
        email: "",
        name: "",
        second_name: "",
        last_name: "",
        position: "",
        organization: "",
      },
      autoLogin: false,
      rememberMe: false,
    },
    logging: false,
    errors: {},
    timer: null,
  }),
  methods: {
    registerDone() {
      this.$router.push("/authentication/login");
    },
    validate() {
      if (this.sended === true) {
        this.$http
          .put("auth/register?validate=true", {
            "register-form": this.data.body, // Axios
          })
          .then((response) => {
            // JSON responses are automatically parsed.
            // this.errors = response.data.errors
            if (response.data.errors) {
              this.errors = [];
              for (var prop in response.data.errors) {
                var key = prop.replace("register-form-", "");
                this.$set(this.errors, key, response.data.errors[prop][0]);
              }
            }
          });
      }
    },
    register() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        if (this.data.body.password !== this.data.body.password2) {
          this.$set(
            this.errors,
            "password",
            "Введенные вами пароли не совпадают"
          );
          this.saving = false;
          return false;
        }
        this.logging = 0;
        this.timer = setInterval(() => {
          if (this.logging < 100) {
            this.logging += 10;
          }
        }, 10);
        this.$auth
          .register({
            data: { "register-form": this.data.body },
            redirect: "/authentication/login",
          })
          .then(() => {
            alert(
              "Письмо с ссылкой на подтверждение учетной записи отправлено Вам на email"
            );
            clearInterval(this.timer);
            this.logging = false;
            this.successRegister = true;
          })
          .catch((e) => {
            this.errors = e;
            clearInterval(this.timer);
            this.logging = false;
          });
      }
    },
  },
};
</script>
